.dashboard_wrapper {
  padding: 25px 20px;
}

.side-bar_wrapper {
  box-shadow: 0px 0px 0px 1px #fff;
  height: 94vh;
  background: #fff;
  border-radius: 12px;
  position: relative;
}

.side-bar_wrapper .sidebar_logo {
  height: auto;
  width: 150px;
  margin: 6px auto;
}

.side-bar_wrapper .sidebar_logo img {
  height: 100%;
  width: 100%;
}

.dashboard_tabs_wrapper .dashboard_tab_img {
  height: 28px;
  width: 28px;
}

.dashboard_tabs_wrapper ul {
  padding: 0px;
  margin: 0px;
}
.dashboard_tabs_wrapper ul li {
  list-style: none;
  text-decoration: none;
}
.dashboard_tabs_wrapper ul li:hover {
  background-color: #f0f0f0;
  border-radius: 7px;
}

.dashboard_tabs_wrapper ul li a {
  text-decoration: none;
}
.dashboard_tabs {
  padding: 10px;
}
.active_dashboard_tabs {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 7px;
}

.dashboard_tabs_wrapper .dashboard_tab_img img {
  height: 100%;
  width: 100%;
}

.dashboard_tab_content h4 {
  font-size: 17px;
  font-family: "Inter", sans-serif;
}

.dashboard_tabs_wrapper .dashboard_tab_content h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 5px;
  font-family: "Inter", sans-serif;
  color: rgba(30, 30, 30, 0.5);
}

.dashboard_btn {
  position: absolute;
  bottom: 25px;
  width: 90%;
  text-align: center;
}

.dashboard_btn button {
  background: #e74c3c;
  padding: 8px 28px;
  border-radius: 10px;
  outline: none;
  border: none;
  color: #fff;
}
.dashboard_btn img{
  width: 20px;
  height: 20px;
}
