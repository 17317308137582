.testimonial_wrapper{

}

.testimonial_wrapper_bg_main{
    background-image: url(../../../../public/img/testimonial_bg1.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    background-size: contain;
    display: flex;
    align-items: center;
}

.testimonial_wrapper_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20%;
}
.testimonial_wrapper_box h2{
    font-weight: 600;
    color: #fff;
}
.client_testimonial_users_details{
    display: flex;
    align-items: center;
    padding: 20px 0px;
}

.testimonial_arrow_buttons{
    background-color: #fff;
    outline: none;
    border: 0.5px solid #ddd;
    border-radius: 10px;
    height: 50px;
    width: 50px;
}
.testimonial_arrow_buttons svg{
    font-size: 30px;
    padding: 4px;
}