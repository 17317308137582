body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flower {
  background: url(/public/img/heart.png) no-repeat center center;
  background-size: cover;
  width: 30px;
  height: 30px;
}
.button_anim{ 
  position: relative;
   overflow: hidden;
   width: 200px;
   height: 50px;
   border:1px solid gray;
   margin-left:20px;
   
   border-radius:10px ;
   background-color: #fff;
}

.active_tab_orde_table{
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  background-color: #72cf02;
  margin: 0px 5px;
}

.inactive_tab_orde_table{
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 8px 20px;
  background-color: #fff;
  margin: 0px 5px;
}