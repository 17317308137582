.choose_trip_wrapper {
  padding: 5% 5%;
  background: #f2f2f2;
}

.choose_search_btn {
  position: absolute;
  top: 3px;
}

.choose_search_wrapper input {
  padding: 8px 35px;
}

.choose_trip_form .form-select {
  padding: 8px 12px;
}

.choose_trip_content {
  padding-right: 50px;
}

.choose_trip_form {
  width:100%;
}

.choose_search_wrapper .form-select,
.choose_search_wrapper input {
  border: 1px solid #ccc;
}

.choose_trip_content span {
  display: block;
  color: #1c1b1f;
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.choose_trip_content h2 {
  color: #1c1b1f;
  font-size: 32px;
  line-height: 57px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.choose_trip_content p {
  color: #999999;
  font-size: 15px;
  font-family: "Inter", sans-serif;
}

.calendar {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  position: absolute;
  z-index: 1;
  display: none;
}

.header {
  background-color: #3498db;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#prevBtn,
#nextBtn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

#monthYear {
  font-size: 18px;
  font-weight: bold;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding: 10px;
}

.day {
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.day.current {
  background-color: #3498db;
  color: white;
}

.day.selected {
  background-color: #2ecc71;
  color: white;
}

#dateInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.lower_choose_trip_form {
  border-radius: 8px;
  box-shadow: 0px 0px 3px 1px #e7e4e4;
  padding: 12px 20px 20px;
  margin-top: 12px;
  background: #fff;
}

.lower_choose_trip_form label {
  margin-top: 12px;
  font-size: 15px;
  color: #999999;
  font-family: "Inter", sans-serif;
  margin-bottom: 3px;
  /* font-weight: 500; */
}

span {
  cursor: pointer;
}

.minus,
.plus {
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-top: 0;
}

.minus svg,
.plus svg {
  font-size: 14px;
}

.number span {
  height: 34px;
  width: 50px;
  text-align: center;
  font-size: 21px;
  /* border:1px solid #ddd; */
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: #f2f2f2;
  font-family: "Inter", sans-serif;
}

.number {
  border: 1px solid #ddd;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.choose_trip_form_btn {
  margin-top: 20px;
  width: 100%;
}

.choose_trip_form_btn {
  width: 100%;
  text-align: center;
  background: #11abe7;
  border-radius: 8px;
  outline: none;
  color: #fff;
  box-shadow: none;
  font-weight: 600;
  font-size: 18px;
  border: none;
  padding: 8px 0;
}

.choose_trip_form_btn .btn {
  color: #fff;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.choose_trip_form_btn:hover{
    background: #11abe7;
    color: #fff;
}