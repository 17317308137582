.holiday_packages_wrapper_sec_heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.holiday_packages_wrapper_sec_heading h2{
    font-weight: 600;
}

.holiday_packages_wrapper{
    padding: 2% 5%;
}

.slick-slide img {
    display: block;
    width: 100%;
    height: 230px;
    padding: 5px;
    border-radius: 10px;
  }

.holiday_packages_wrapper_sec_heading svg{
    background-color: #fff;
    border-radius: 5px;
    border: 0.5px solid #ddd;
    font-size: 41px;
    padding: 6px;
    cursor: pointer;
}
.holiday_package_carousel_card_main_text{
    position: absolute;
    bottom: 10%;
    left: 5%;
}

.holiday_package_carousel_card_main_text h4{
    font-weight: 600;
    color: #fff;
}

.holiday_package_carousel_card_main_text h6{
 color: #fff;
}


@media screen and (min-width: 1380px) and (max-width: 1440px){
    .holiday_package_carousel_card_main_text h4{
        font-size: 18px;
    }
    .holiday_package_carousel_card_main_text h6{
        font-size: 16px;
    }
}

@media screen and (min-width: 1201) and (max-width: 1380px){
    .holiday_package_carousel_card_main_text h4{
        font-size: 18px;
    }
    .holiday_package_carousel_card_main_text h6{
        font-size: 16px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px){
    .holiday_package_carousel_card_main_text h4{
        font-size: 18px;
    }  
    .holiday_package_carousel_card_main_text h6{
        font-size: 16px;
    } 
}

@media screen and (min-width: 968px) and (max-width: 1024px){
    .holiday_package_carousel_card_main_text h4{
        font-size: 18px;
    }  
    .holiday_package_carousel_card_main_text h6{
        font-size: 16px;
    }
}

@media screen and (min-width: 526px) and (max-width: 967px){

}

@media screen and (min-width: 425px) and (max-width: 525px){

}
@media screen and (min-width: 381px) and (max-width: 424px){

}
@media screen and (min-width: 220px) and (max-width: 380px){

}