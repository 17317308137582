
.form {
  /* background: rgba(19, 35, 47, 0.9); */
  padding: 40px;
  max-width: 600px;
  margin: 0px auto;
  border-radius: 4px;
  /* box-shadow: 0 4px 10px 4px rgba(19, 35, 47, 0.3); */
}

.tab-group {
  list-style: none;
  padding: 0;
  background:#d1eef9;
  padding: 8px 13px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 12px;
}

.tab-group:after {
  content: "";
  display: table;
  clear: both;
}


.tab-group li  {
      display: block;
      text-decoration: none;
      padding: 10px;
      /* background: rgba(24, 46, 69, 1); */
      color: #11ABE7;
      font-size: 15px;
      float: left;
      width: 50%;
      text-align: center;
      cursor: pointer;
      transition: 0.5s ease;
      border-radius: 8px;
      /* margin-left: 10px; */
      font-weight: 600;
      font-family: "Inter", sans-serif;
  }

.tab-group .active  {
  background: #11ABE7;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}

.tab-content > div:last-child {
  display: none;
}

h1 {
  text-align: center;
  /* color: #ffffff; */
  font-weight: 300;
  margin: 0 0 40px;
}

input,
textarea {
  font-size: 22px;
  display: block;
  width: 100%;
  /* height: 100%; */
  padding: 5px 10px;
  background: none;
  background-image: none;
  border: 1px solid #a0b3b0;
  /* color: #ffffff; */
  border-radius: 0;
  transition: border-color 0.25s ease, box-shadow 0.25s ease;
}

textarea {
  border: 2px solid #a0b3b0;
  resize: vertical;
}

.field-wrap {
  position: relative;
  margin-bottom: 40px;
}

.top-row:after {
  content: "";
  display: table;
  clear: both;
}
.top-row > div {
  float: left;
  width: 48%;
  margin-right: 4%;
}
.top-row > div:last-child {
  margin: 0;
}

.button {
  border: 0;
  outline: none;
  border-radius: 0;
  padding: 15px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: #1ab188;
  /* color: #ffffff; */
  transition: all 0.5s ease;
  -webkit-appearance: none;
}

.button-block {
  display: block;
  width: 100%;
}

.forgot {
  margin-top: -20px;
  text-align: right;
}

.login_img{
  width: 100%;
  height: 100vh;
}

.login_img img{
  width: 100%;
  height: 100%;
}

.login_form{
  width:60%;
}

.login_form form{
  margin-top: 50px;
}

.login_form_heading{
   margin-bottom: 15px;
}

.login_form_heading span{
  font-size: 16px;
  display: inline-block;
  margin-left: 6px;
  color: rgba(172, 172, 172, 1);
  font-family: "Inter", sans-serif;
}

.login_form_heading h2{
   font-size: 34px;
   font-weight: 800;
   color: #11ABE7;
   margin-bottom: 0px;
   font-family: "Inter", sans-serif;
}

.login_form_wrapper form label{
  font-size: 14px;
  font-family: "Inter", sans-serif;
  text-transform: capitalize;
  /* margin-left: 7px; */
  position: absolute;
  top: -12px;
  z-index: 1;
  left: 2em;
  background-color: white;
  padding: 0 5px;
  color: #11ABE7;
}


.login_form_wrapper form .form-control, .login_form_wrapper form .form-select {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  border:1px solid #11ABE7;
  margin-top: 35px;
  padding: 0 0 0 15px;
}

.input-wrapper{
  position: relative;
}

.login_form_wrapper form input{
  padding: 0;
  border: 1px solid gray;
  border-radius: 6px;
  position: relative;
  width: 200px;
  line-height: 6ex;
}

.login_form_wrapper form input::placeholder , .login_form_wrapper form .form-select{
  font-size: 13px;
  padding-left: 20px;
  font-family: "Inter", sans-serif;
  color: rgba(172, 172, 172, 1);
}

.login_form_wrapper form .form-check .form-check-input{
  float: left;
  margin-left: -10px;
  font-family: "Inter", sans-serif;
}

.login_form_wrapper form .form-check-input {
  width: 10px;
  height: 15px;
  padding: 7px;
}

.remember_wrapper .form-check-label{
  font-size: 12px !important;
  font-family: "Inter", sans-serif;
  position: static;
  font-size: 12px !important;
  font-family: "Inter", sans-serif;
  position: static;
  margin-left: 5px;
}

.remember_wrapper a{
  font-size: 12px !important;
  font-family: "Inter", sans-serif;
}

.login_form_btn .btn{
  background: #11ABE7;
  width: 100%;
  color: #ffffff;
  font-weight: 400;
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

.login_form form p{
  text-align: center;
  font-size: 14px;
  margin-top: 7px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.login_form form p a{
   color: #11ABE7;
   font-weight: 500;
   font-family: "Inter", sans-serif;
}


.show_password_icon{
  position: absolute;
  top: 30%;
  right: 10%;
}


@media screen and (max-width: 1025px) and (min-width: 1200px){

}