.segments_wrappers {
  padding: 2% 5%;
}
.segment_card {
  cursor: pointer;
}
.segment_card a {
  text-decoration: none;
  list-style: none;
}
.segment_card_name p {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 12px;
  color: #000;
  text-decoration: none;
  list-style: none;
}

.segment_card_img {
  display: flex;
  justify-content: center;
}

.segment_card_img img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.home_segments_sec_heading h2 {
  padding: 0px 0px 10px 0px;
  font-weight: 600;
}
