.package_detail_page_wrapper {
  margin: 2% 5%;
}

.package_detail_gallery_wrapper {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px 8px 0 0;
}

.package_detail_gallery_wrapper h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0px;
  font-family: "Inter", sans-serif;
}

.package_gallery_display_img {
  height: 100%;
  width: 100%;
}

.package_gallery_display_img img {
  height: 100%;
  width: 100%;
}

.side_gallery {
  border-radius: 12px;
}

.package_side_gallery_img {
  height: 100%;
  width: 100%;
  border-radius: 12px;
}

.package_side_gallery_img .card {
  border-radius: 12px;
  border: none;
  height: 257px;
  width: 100%;
  margin-top: 12px;
}

.package_side_gallery_img img {
  height: 100%;
  width: 100%;
  border-radius: 12px;
}

.package_side_gallery_img .card-img-overlay {
  top: 35%;
  left: 32%;
}

.package_side_gallery_img .card-img-overlay button {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.package_side_gallery_img .card-img-overlay button a {
  text-decoration: underline;
  color: #ffffff;
}

.hotel_titles {
  margin:40px 0px 0px 0px;
}
.hotel_titles h2 {
  font-weight: 600;
}

.hotel_titles h6 {
  font-weight: 500;
}

.hotel_titles p{

}

.package_flatoff_cards {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 8px;
}

.package_flatoff_cards span {
  display: block;
  color: rgba(30, 30, 30, 0.4);
  font-size: 12px;
  font-family: "Inter", sans-serif;
  text-align: end;
}

.package_flatoff_cards h4 {
  color: #1e1e1e;
  font-size: 16px;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.package_flatoff_cards h2 {
  color: #ff6600;
  font-size: 18px;
  font-weight: 600;
  margin: 4px 0;
  font-family: "Inter", sans-serif;
}

.package_flatoff_cards p {
  color: rgba(30, 30, 30, 0.4);
  font-size: 13px;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.flatoff_btn {
  text-align: end;
}

.flatoff_btn button {
  border: none;
}

.flatoff_btn .btn {
  color: #11abe7;
  font-size: 13px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  padding: 0;
  text-align: end;
}

.flatoff_img {
  height: 130px;
  width: 130px;
}

.flatoff_img img {
  height: 150px;
  max-width: 100%;
  border-radius: 8px;
}
