.package_cancellation_policy_wrapper{
    padding: 0px 100px;
   }
  
   /* .package_cancellation_policy_wrapper .details_tabs_content_wrapper{
     max-height: 500px;
     overflow-y: scroll;
   } */
  
   .package_cancellation_policy_wrapper .package_detail_tabs_content{
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 75px;
  }
  
  
  
  .package_detail_tabs_content::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  }
  
  .package_detail_tabs_content::-webkit-scrollbar
  {
      width: 6px;
    border-radius:12px;
      background-color: #c7c7c7;
  }
  
  .package_detail_tabs_content::-webkit-scrollbar-thumb
  {
      background-color: #f2f2f2;
  }
  