.footer_wrapper{
    background-color: #1F2937;
    padding: 100px 75px;
 }
 

 .footer_sec_heading h4{
   color:#ffffff;
   font-size: 22px;
   margin-bottom: 15px;
   font-family: "Inter", sans-serif;
 }
 
 .quick_links ul li{
  margin-top: 7px;
 }

 .quick_links ul li {
  color:#ffffff;
  font-size: 16px;
  font-weight: 300;
  font-family: "Inter", sans-serif;
  list-style: none;
  text-decoration: none;
  cursor: pointer;
 }
 .quick_links ul li:hover{
    text-decoration: underline;
 }

 .footer_social_links_wrappers .social_link{
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
 }  

 .footer_social_links ul{
   display: flex;
 }

 .footer_social_links ul li{
   margin-right: 12px;
 }

 .footer_social_links ul li a svg{
   color: #1F2937;
   font-size: 20px;
   display: flex;
 }

 .footer_logo{
   display: flex;
   margin-top: 12px;
 }

