.update_profile_form {
    border-radius: 8px;
    padding: 12px 20px 20px;
    margin-top: 12px;
    background: #fff;
  }
  
  .update_profile_form label {
    margin-top: 12px;
    font-size: 15px;
    color: #999999;
    font-family: "Inter", sans-serif;
    margin-bottom: 3px;
    /* font-weight: 500; */
  }
  
  .user_profile_image img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid grey;
  }
  .user_profile_image input{
    display: none;
  }
  .user_profile_image svg{
    font-size: 24px;
    position: absolute;
    left: 18%;
    cursor: pointer;
  }
  .user_profile_image{
    position: relative;
  }

