
.content_sidebar {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 10px;
  border-radius: 8px;
}

.maldives_resort_filters_heading span{
  color: #E74C3C;
}

.maldives_filters_resort_main{
  position: sticky;
  top: 10px;
}

.resort_filter_property_type_dropdown{
  background-color: #fff;
  padding: 7px 20px;
  border: 1px solid grey;
  outline: none;
  border-radius: 5px;
  width: 100%;
}
.resort_filter_property_type_dropdown option{
  padding: 8px 10px;
  background-color: #fff;
}
