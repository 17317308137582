.select_button_package_Add_on{
    border-radius: 8px;
    padding: 8px 20px;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    color: #000;
}
.selected_button_package_Add_on{
    border-radius: 8px;
    padding: 8px 20px;
    background-color: #3178e2;
    border: 1px solid #3178e2;
    color: #fff;
}