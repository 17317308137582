.detail_navs_wrapper{
    background-color: #ffffff;
    padding: 15px 10px;
    box-shadow: 0 0 5px #dcdcdc;
    margin: 10px 0px;
   }
.resort_tabs_navbar ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.resort_tabs_navbar ul li{
    color: #000;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}


.resort_tabs_navbar ul .active{
    color: #ff6600;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}