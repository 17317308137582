.package_policy_wrapper{
    padding: 0px 100px;
    margin-top: 50px;
  }
  
  .package_policy_wrapper .detail_tabs_content{
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 75px;
  }
  