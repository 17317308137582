



.image_gallery_main img {
    width: 65px;
    height: 65px;
    display: block;
  }
  
  .image_gallery_main .img-display {
    overflow: hidden;
  }
  
  .image_gallery_main .img-showcase {
    display: flex;
    height: 350px;
    width: 100%;
    transition: all 0.5s ease;
  }
  
  .image_gallery_main .img-showcase img {
    width: 100%;
    height: auto;
  }
  
  .image_gallery_main .img-select {
    display: flex;
    justify-content: center;
  }
  
  .image_gallery_main .img-item {
    margin: 0.3rem;
  }
  
  .image_gallery_main .img-item:nth-child(1),
  .image_gallery_main .img-item:nth-child(2),
  .image_gallery_main .img-item:nth-child(3) {
    margin-right: 0;
  }
  
  .image_gallery_main .img-item:hover {
    opacity: 0.8;
  }
  
  .list_details_wrapper {
    padding: 12px;
  }
  
  .list_rating {
    font-size: 17px;
    color: #8d8d8d;
    font-family: "Inter", sans-serif;
  }
  
  .list_rating img {
    height: 17px;
    width: 18px;
    margin-left: 4px;
  }
  
  
  
  .sec_heading h4 {
    font-size: 20px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    margin-top: 12px;
    margin-bottom: 5px;
  }
  
  .list_content p {
    font-size: 12px;
    font-weight: 500;
    color: rgba(30, 30, 30, 0.8);
    margin-bottom: 6px;
    font-family: "Inter", sans-serif;
  }
  
  .list_location {
    margin-top: 0;
  }
  
  .list_location p {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: rgba(30, 30, 30, 0.8);
    font-family: "Inter", sans-serif;
  }
  
  .list_location .list_location_icon {
    height: 30px;
    width: 30px;
  }

  
  .list_amenities_wrapper {
    margin-top: 10px;
  }
  
  .list_amenities_wrapper h4 {
    font-size: 18px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    margin-bottom: 5px;
  }
  
  .list_amenities_wrapper ul {
    padding: 0px;
    margin-right: 20px;
  }
  
  .list_amenities_wrapper ul li {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: rgba(30, 30, 30, 0.4);
    font-family: "Inter", sans-serif;
    margin-top: 10px;
  }
  
  .list_amenities_wrapper ul li img {
    width: 25px;
    height: 25px;
    margin-right: 7px;
  }
  
  .add_on_btn_wrapper {
    margin-top: 25px;
  }
  
  
  
  .offer_wrapper p {
    color: #ff6600;
    font-size: 12px;
    font-weight: 500;
    background-color: rgba(114, 207, 2, 0.2);
    font-family: "Inter", sans-serif;
    text-align: center;
    width: fit-content;
    padding: 4px 25px;
    border-radius: 4px;
    margin: 10px auto;
  }

  .image_gallery_main_box_list_last_section_adults{
    width: 90%;
  }
  .image_gallery_main_box_list_last_section_adults label{
    font-size: 14px;
    margin: 0px;
  }
  
  .image_gallery_main_box_list_last_section_child label{
    font-size: 14px;
    margin: 0px;
  }
  .image_gallery_main_box_list_last_section_adults svg{
    font-size: 16px;
    font-weight: 500;
    color: grey;
    cursor: pointer;
  }
  .image_gallery_main_box_list_last_section_adults span{
    font-size: 18px;
    font-weight: 600;
    color: grey ;
  }

  .image_gallery_main_box_list_last_section_off_wrapper {
    margin-top: 15px;
    text-align: end;
  }
  .image_gallery_main_box_list_last_section_off_wrapper .strike{
    font-size: 18px;
    color: grey;
  }
  
  .image_gallery_main_box_list_last_section_off_wrapper span {
    font-size: 14px;
    background: rgba(255, 102, 0, 0.5);
    color: #ff6600;
    width: fit-content;
    font-family: "Inter", sans-serif;
    margin-left: auto;
    padding: 3px 12px;
    display: block;
    border-radius: 4px;
  }
  
  .image_gallery_main_box_list_last_section_off_wrapper h4 {
    font-size: 22px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    /* padding: 3px 12px; */
    display: block;
    border-radius: 4px;
    margin: 10px 0 0;
  }
  
  .image_gallery_main_box_list_last_section_off_wrapper p {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    margin-left: auto;
    /* padding: 3px 12px; */
    display: block;
    border-radius: 4px;
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
  }
  
  
  
  .list_login_btn button {
    color: #11abe7;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    margin: 0 auto;
    display: flex;
    margin-top: 20px;
  }
  
  
  .image_gallery_main_box {
      margin: 1% 0%;
      border-radius: 10px;
      padding: 10px 10px;
  }
  
  .maldives_main_room_listing_card_box {
    border: 0.5px solid #ff6600;
    margin: 1% 6%;
    border-radius: 10px;
    padding: 10px 10px;
}

  .image_gallery_main_box_list_last_section {
      border-left: 1px solid #ff6600;
      padding: 7px 25px;
      width: 100%;
    }
  
  .image_gallery_main_box_list_last_section .book_now_button{
      background: #11abe7;
      padding: 8px 20px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      border-radius: 8px;
      outline: none;
      border: none;
      margin-top: 10px;
  }
  
  .image_gallery_main_box_list_last_section .view_details_button{
      background: #fff;
      padding: 8px 20px;
      font-size: 16px;
      font-weight: 500;
      color: #6d6c6c;
      border-radius: 8px;
      outline: none;
      border: 0.5px solid #6d6c6c;
      margin-top: 10px;
  }
  
  .maldives_resort_listing_middle_section_buttons .add_on{
      outline: none;
      border: none;
      background-color: #fff;
      margin: 0 0 0 20px;
      font-weight: 600;
      color: #11abe7;
  }
  
  .maldives_resort_listing_middle_section_buttons .cancellation{
      outline: none;
      border: none;
      background-color: #fff;
      font-weight: 600;
      color: green;
      margin: 0px;
      padding: 0px;
  }
  
  .rating_verify span{
      font-weight: 700;
  }

.lists_rooms_btn{
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #dcdcdc;
  color: #000;
  padding: 5px 20px;
  margin: 5px 10px;
}
.lists_rooms_btn_active{
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #11abe7;
  color: #fff;
  padding: 5px 20px;
  margin: 5px 10px;
}
  /* /////////////////////////////// rooms css */

  
  .maldives_main_room_listing_card_box_list_last_section_off_wrapper {
    margin-top: 15px;
    text-align: end;
  }
  .maldives_main_room_listing_card_box_list_last_section_off_wrapper .strike{
    font-size: 18px;
    color: grey;
  }
  
  .maldives_main_room_listing_card_box_list_last_section_off_wrapper span {
    font-size: 14px;
    background: rgba(255, 102, 0, 0.5);
    color: #ff6600;
    width: fit-content;
    font-family: "Inter", sans-serif;
    margin-left: auto;
    padding: 3px 12px;
    display: block;
    border-radius: 4px;
  }
  
  .maldives_main_room_listing_card_box_list_last_section_off_wrapper h6 {
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    /* padding: 3px 12px; */
    display: block;
    border-radius: 4px;
    margin: 10px 0 0;
  }
  .maldives_main_room_listing_card_box_list_last_section_off_wrapper h4 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    /* padding: 3px 12px; */
    display: block;
    border-radius: 4px;
    margin: 10px 0 0;
  }
  .maldives_main_room_listing_card_box_list_last_section_off_wrapper button {
    font-size: 12px;
    font-family: "Inter", sans-serif;
    display: block;
    margin: 0px 5px;
    padding: 2px 5px;
    color: #ffffff;
    background-color: #11abe7;
    border-radius: 5px;
    cursor: pointer;
    border: none;
  }
  .resort_load_more_btn{
    background: #11abe7;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border-radius: 8px;
    outline: none;
    border: none;
    margin: 20px 0px;

  }

  .maldives_main_room_listing_card_box_list_last_section {
    border-left: 1px solid #ff6600;
    padding: 7px 25px;
    width: 100%;
  }

.maldives_main_room_listing_card_box_list_last_section .book_now_button{
    background: #11abe7;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border-radius: 8px;
    outline: none;
    border: none;
    margin-top: 10px;
}

.maldives_main_room_listing_card_box_list_last_section .view_details_button{
    background: #fff;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #6d6c6c;
    border-radius: 8px;
    outline: none;
    border: 0.5px solid #6d6c6c;
    margin-top: 10px;
}
@media screen and (max-width: 1024px) {
  .image_gallery_main img {
    width: 45px;
    height: 45px;
    display: block;
  }
  .list_img_gallery{
    width: 100%;
  }
  .list_rating img {
    height: 14px !important;
    width: 14px !important;
    margin-left: 2px;
  }
  .image_gallery_main .img-showcase {
    height: 220px;
  }
  .image_gallery_main .img-showcase img {
    border-radius: 5px;
  }
  .sec_heading h4{
    font-size: 18px;
  }
  .list_location p{
    font-size: 14px;
  }
  .list_location .list_location_icon{
    height: 25px;
  }
  .rating_verify{
    font-size: 13px;
  }
  .list_rating{
    font-size: 14px;
  }
  .image_gallery_main_box_list_last_section .view_details_button{
    padding: 5px 20px;
    font-size: 14px;
  }
  .image_gallery_main_box_list_last_section_off_wrapper h4{
    font-size: 18px;
  }
  .offer_wrapper p{
    padding: 4px 15px;
  }
  .maldives_main_room_listing_card_box{
    margin: 1% 1%;
  }
  .list_amenities_wrapper h4{
    font-size: 15px;
  }
  .maldives_main_room_listing_card_box_list_last_section{
    padding: 7px 10px;
  }
}

@media screen and (max-width: 1200px) {
  .image_gallery_main img {
    width: 45px;
    height: 45px;
    display: block;
  }
  .list_img_gallery{
    width: 100%;
  }
  .list_rating img {
    height: 14px !important;
    width: 14px !important;
    margin-left: 2px;
  }
  .image_gallery_main .img-showcase {
    height: 250px;
  }
  .image_gallery_main .img-showcase img {
    border-radius: 5px;
  }
  .sec_heading h4{
    font-size: 18px;
  }
  .list_location p{
    font-size: 14px;
  }
  .list_location .list_location_icon{
    height: 25px;
  }
  .rating_verify{
    font-size: 13px;
  }
  .list_rating{
    font-size: 14px;
  }
  .image_gallery_main_box_list_last_section .view_details_button{
    padding: 5px 20px;
    font-size: 14px;
  }
  .image_gallery_main_box_list_last_section_off_wrapper h4{
    font-size: 18px;
  }
  .offer_wrapper p{
    padding: 4px 15px;
  }
  .maldives_main_room_listing_card_box{
    margin: 1% 1%;
  }
  .list_amenities_wrapper h4{
    font-size: 15px;
  }
  .maldives_main_room_listing_card_box_list_last_section{
    padding: 7px 10px;
  }
}
@media screen and (max-width: 1380px) {
  .image_gallery_main img {
    width: 45px;
    height: 45px;
    display: block;
  }
  .list_img_gallery{
    width: 100%;
  }
  .list_rating img {
    height: 14px !important;
    width: 14px !important;
    margin-left: 2px;
  }
  .image_gallery_main .img-showcase {
    height: 250px;
  }
  .image_gallery_main .img-showcase img {
    border-radius: 5px;
  }
  .sec_heading h4{
    font-size: 18px;
  }
  .list_location p{
    font-size: 14px;
  }
  .list_location .list_location_icon{
    height: 25px;
  }
  .rating_verify{
    font-size: 13px;
  }
  .list_rating{
    font-size: 14px;
  }
  .image_gallery_main_box_list_last_section .view_details_button{
    padding: 5px 20px;
    font-size: 14px;
  }
  .image_gallery_main_box_list_last_section_off_wrapper h4{
    font-size: 18px;
  }
  .offer_wrapper p{
    padding: 4px 15px;
  }
  .maldives_main_room_listing_card_box{
    margin: 1% 1%;
  }
  .list_amenities_wrapper h4{
    font-size: 15px;
  }
  .maldives_main_room_listing_card_box_list_last_section{
    padding: 7px 10px;
  }
}
  @media screen and (max-width: 1440px) {
    .image_gallery_main img {
      width: 45px;
      height: 45px;
      display: block;
    }
    .list_img_gallery{
      width: 100%;
    }
    .list_rating img {
      height: 14px !important;
      width: 14px !important;
      margin-left: 2px;
    }
    .image_gallery_main .img-showcase {
      height: 250px;
    }
    .image_gallery_main .img-showcase img {
      border-radius: 5px;
    }
    .sec_heading h4{
      font-size: 18px;
    }
    .list_location p{
      font-size: 14px;
    }
    .list_location .list_location_icon{
      height: 25px;
    }
    .rating_verify{
      font-size: 13px;
    }
    .list_rating{
      font-size: 14px;
    }
    .image_gallery_main_box_list_last_section .view_details_button{
      padding: 5px 20px;
      font-size: 14px;
    }
    .image_gallery_main_box_list_last_section_off_wrapper h4{
      font-size: 18px;
    }
    .offer_wrapper p{
      padding: 4px 15px;
    }
    .maldives_main_room_listing_card_box{
      margin: 1% 1%;
    }
    .list_amenities_wrapper h4{
      font-size: 15px;
    }
    .maldives_main_room_listing_card_box_list_last_section{
      padding: 7px 10px;
    }
  }


  @media screen and (max-width: 1620px) {
    .image_gallery_main img {
      width: 35px;
      height: 35px;
      display: block;
    }
    .list_img_gallery{
      width: 100%;
    }
    .list_rating img {
      height: 17px;
      width: 18px;
      margin-left: 4px;
    }
  }