.edit_profile_form {
  width: 80%;
}

.edit_profile_form form label {
  font-size: 15px;
  margin-bottom: 3px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.5);
  font-family: "Inter", sans-serif;
}

.edit_profile_form form input {
  background: #f5f5f5;
  border-color: rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 8px;
  width: 80%;
}

.form-control:focus {
  border-color: rgba(0, 0, 0, 0.2);
  background: #f5f5f5;
  box-shadow: none;
}

.save_button {
  background-color: #11abe7;
  width: fit-content;
  padding: 7px 45px;
  border: none;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  font-weight: 600;
}

.cancel_button {
    background-color: #ffffff;
    width: fit-content;
    padding: 7px 45px;
    border: none;
    font-size: 16px;
    color: #000000;
    border-radius: 8px;
    font-weight: 600;
    border: 1px solid grey;
    margin-left: 20px;
}

.dashboard_profile_name {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  margin-left: 8px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.5);
}
