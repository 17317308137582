.reviews_sec_wrapper {
  padding: 0px 100px;
}

.review_wrapper {
  background-color: #fff;
  /* padding: 25px; */
  padding: 25px 0;
  height: auto;
  border-radius: 8px;
}

.review_wrapper .detail_tabs_heading {
  padding: 0 25px;
  border-bottom: 1px solid #e6e6e6;
}

.detail_tabs_heading a {
  color: #11abe7;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.review_wrapper .detail_tabs_heading,
.detail_tabs_content {
  width: 100%;
}

.rating_bar_wrapper {
  padding: 15px 25px;
  border-bottom: 1px solid #e6e6e6;
}

.rating_content {
  width: 175px;
  padding: 20px;
  border-radius: 7px;
  background-color: #11abe7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rating_content h4 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 500;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.rating_content p {
  font-size: 37px;
  color: #ffffff;
  font-weight: 600;
  margin: 4px 0;
  font-family: "Inter", sans-serif;
}

.rating_content span {
  display: block;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  font-family: "Inter", sans-serif;
  margin: 3px 0;
}

.review_sec_wrapper {
  padding: 45px 25px;
  border: 1px 0px;
  border-color: #e6e6e6;
}

.review_sec {
  box-shadow: 0px 0px 1px 2px #e6e6e6;
  padding: 25px;
  border-radius: 7px;
  margin-top: 25px;
}

.review_header {
  display: flex;
  align-items: center;
}

.review_header .review_sec_img img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.review_heading {
  margin-left: 12px;
}

.review_heading span {
  font-size: 13px;
  color: rgba(30, 30, 30, 0.5);
  font-family: "Inter", sans-serif;
}

.review_heading .review_main_heading {
  display: flex;
  align-items: center;
}

.review_heading .review_main_heading h4 {
  margin: 0;
  font-size: 17px;
  color: #1e1e1e;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.review_content {
  margin-top: 12px;
}

.review_content p {
  font-size: 14px;
  margin-left: 7px;
  color: #1e1e1e;
  font-family: "Inter", sans-serif;
}

.review_content_img_wrapper {
  margin-top: 15px;
  display: flex;
}

.review_content_img {
  height: 125px;
  width: 125px;
  border-radius: 8px;
  margin-right: 12px;
}

.review_content_img img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.review_rating_star h4 {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.review_rating_star ul {
  margin: 0;
  margin-left: 4px;
}
.review_rating_star ul li {
  list-style: none;
}
.review_rating_star ul li img {
  height: 17px;
  width: 17px;
  margin: 0 3px;
}

.review_writting_sec {
  padding: 20px 25px;
}

.form-floating > label {
  font-size: 15px;
  font-weight: 500;
  color: rgba(30, 30, 30, 0.5);
  font-family: "Inter", sans-serif;
}

.review_writting_sec button {
  background: #11abe7;
  padding: 7px 25px;
  margin-right: 7px;
}

.review_writting_sec button a {
  color: #ffffff;
  font-family: "Inter", sans-serif;
}
.submit_btn_rating{
  background: #11abe7;
  padding: 8px 25px;
  margin-right: 7px;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 7px;
}
.review_writting_sec .review_writting_btn label {
  cursor: pointer;
  height: 100px;
  width: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e6e6;
  margin-right: 10px;
}

.review_writting_sec .icon {
  font-size: 25px;
  color: rgba(28, 27, 31, 0.5);
  font-variation-settings: "OPSZ" 96, "FILL" 0;

  &.uploading {
    animation: hithere 1s ease infinite;
  }

  &.done {
    animation: bounce 0.6s ease forwards;
  }
}

.review_writting_sec #first_img  {
  display: none;
}
.review_writting_sec #second_img  {
  display: none;
}
.review_writting_sec #third_img  {
  display: none;
}
.review_writting_sec #forth_img  {
  display: none;
}
.review_writting_sec img{
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
@keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  20% {
    transform: translateY(-15%);
  }
  40% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-7%);
  }
  70% {
    transform: translateY(0%);
  }
  90% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

.word_limits p {
  font-size: 13px;
  color: rgba(30, 30, 30, 0.5);
  font-family: "Inter", sans-serif;
}


.maldives_resort_details_review_upload_file_button ul li{
    list-style: none;
}

.maldives_resort_details_review_upload_file_button ul li svg{
   font-size: 24px;
}