.hero_section_wrapper{
    height: 500px;
    padding: 0 50px;
    background: url(../../../../public/img/hero-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 0;
}

.navbar {
    position: relative;
    display: flex;
    padding: 10px;    
    align-items: center;
}

.nav-items > ul > li {
    position: relative;
    display: inline;
    list-style: none;
    margin: 10px;
    padding: 7px 18px;
    cursor: pointer;
}

.nav-items ul li a  {
    color: white;
    text-decoration: none;
    font-size: 17px;
    font-family: "Inter", sans-serif;
}

.nav-items > ul > li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.17rem;
    /* background-color: #c52726; */
    left: 0;
    bottom: 0;
    transform-origin: 0% 100%;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.nav-items > ul > li:hover::after {
    transform: scaleX(1);
}  
.logout_button{
    outline: none;
    border: none;
    background-color:  #72CF02;
    border-radius: 50px;
    padding: 10px 30px;
    color: #fff !important;
}
.logout_button:hover{
    color: #fff !important;
}

.nav-button {
    background-color:  #72CF02;
    border-radius: 50px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    color: #ffffff;
}
.nav-button img{
    border: 0.5px solid grey;
    border-radius: 50%;
}
.anim-layer {
    /* position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background-color: #72CF02;
    transition: width 0.3s ease, left 0.3s ease; */
}

.nav-button:hover .anim-layer {
    width: 100%;
    left: 0;
}

.nav-button:hover a {
    color: #ffffff;
}

.nav-button a {
    display: block;
    padding: 10px 20px;
    color: #ffffff;
    text-decoration: none;
    position: relative;
    z-index: 1;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 500;
}

#hamburger-menu,
#mobile-menu {
    display: none;
}


.hero_sec_content{
  padding: 90px 0px;
  /* height: 375px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero_sec_content h1{
  font-size: 40px;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 600;
}

.hero_sec_content h2{
  font-size: 42px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.hero_sec_content p{
  font-size: 20px;
  color: #fff;
  font-family: "Inter", sans-serif;
  margin-top: 0 !important;
  text-align: center;
}

.home-nav-profile-dropdown{
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    border: 0.5px solid #dcdcdc;
    position: absolute;
    top: 90%;
    width: 100%;

}
.home-nav-profile-dropdown p{
    color: #000;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0px;
}
.home-nav-profile-dropdown p:hover{
    background-color: #72CF02;
    color: #fff;
}
.home-nav-profile-dropdown svg{
    font-size: 16px;
}

@media screen and (max-width: 1380px) and (min-width: 1440px){
    .nav-items ul li a {
        font-size: 14px !important;
    }
 
}

@media screen and (max-width: 1201) and (min-width: 1380px){
    .nav-items ul li a  {
        font-size: 14px !important;
    }
   
}

@media screen and (max-width: 1025px) and (min-width: 1200px){
    .nav-items ul li a  {
        font-size: 14px !important;
    }

}

@media screen and (max-width: 968px) and (min-width: 1024px){
    .nav-items ul li a  {
        font-size: 14px !important;
    }
  
}