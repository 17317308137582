
.dashboard_main-upper_wrapper {
    background-color: #fff;
    box-shadow: 0px 0px 0px 1px #fff;
    border-radius: 12px;
    padding: 12px 25px;
    margin-top: 25px;
  }
  
 

  .customer_booking_table table thead th{
    font-size: 17px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: 1E1E1E;
  }

  
  .customer_booking_table table tbody th , .customer_booking_table table tbody td{
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: rgba(28, 27, 31, 0.5);
  }
  
  .customer_booking_table table tbody{
    margin-top: 12px;
  }
  
  
  .table tbody td .btn_confirm{
    background-color: rgba(114, 207, 2, 0.2);
    width: 150px;
    border-radius: 8px;
  }
  
  .table tbody td .btn_pending{
    background-color: rgba(246, 195, 55, 0.2);
    width: 150px;
  }
  
  .table tbody td .btn_cancelled{
    background-color: rgba(231, 76, 60, 0.2);
    width: 150px;
  }
  
  .table tbody td button{
    padding: 5px;
  }
  
  .table tbody td button a{
    font-size: 15px;
    font-weight: 500;
    color: rgba(30, 30, 30, 0.5);
    font-family: "Inter", sans-serif;
  }
  
  
  .table>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: none;
    border: none;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
  
  .customer_booking_table table tbody td{

  }
  
  
  .cust_details p{
    font-size: 15px;
    font-weight: 600;
    color: #1E1E1E;
    font-family: "Inter", sans-serif;
  }
  
  .cust_details span{
    font-size: 15px;
    font-weight: 600;
    margin-right: 20px;
    color: rgba(30, 30, 30, 0.5);
    font-family: "Inter", sans-serif;
  }
  
  .customer-dashboard_details{
   margin-top: 25px;
   padding: 50px;
   background-color: #ffffff;
   border-radius: 12px;
  }
  
  .table_profile_img{
    height: 35px;
    width: 35px;
  }
  
  .table_profile_img img{
    height: 100%;
    width:  100%;
  }
  
  .table_profile_name p{
    margin-bottom: 0;
  }
  
  .dashboard_check_in_out p{
    margin-bottom: 0;
    margin-bottom: 3px;
  }
  
  .dashbord_content_btn_wrapper{
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
  
  .dashbord_content_btn_wrapper img{
    height: 20px;
    width: 20px;
  }
  
  .dashbord_content_btn_wrapper button{
     background-color: #ffffff;
     border-radius: 12px;
     width: 175px;
     margin-left: 20px;
  }
  
  .dashbord_content_btn_wrapper button a{
    color: #1E1E1E;
    font-size: 15px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  