
.maldives_room_check_availability_box{
    border-radius: 10px;
    padding: 0px;
}

.maldive_room_inc_dec_box span {

    text-align: center;
    font-size: 16px;
    /* border:1px solid #ddd; */
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: #f2f2f2;
    font-family: "Inter", sans-serif;
    padding: 2px 10px;
  }
  
  .maldive_room_inc_dec_box {
    border: 1px solid #ddd;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 0px 10px;
  }

  .maldives_room_check_availability_box button{
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    color: #fff;
    background-color: #11abe7;
    font-size: 14px;
  }
.maldive_room_remove_btn{
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  color: #fff;
  background-color: #b43322 !important;
  font-size: 14px;
}
.maldive_room_not_available_btn{
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  color: #fff;
  background-color: #b8b8b8 !important;
  font-size: 14px;
}
  .maldives_room_check_availability_box h6{
    font-weight: 600;
  }
  .maldives_rooms_listing_remove_price_btn{
    border-radius: 5px;
    border: none;
    padding: 2px 10px;
    color: #fff;
    background-color: #11abe7;
    font-size: 11px;
  }

.maldives_rooms_listing_middle_section_buttons{
  display: flex;
  margin-top: 10px;
}

.maldives_rooms_listing_middle_section_buttons .cancellation{
    outline: none;
    border: none;
    background-color: #fff;
    font-weight: 600;
    color: red;
}
.maldives_rooms_listing_middle_section_buttons select{
  background-color: #fff;
  outline: none;
  border: 1px solid grey;
  padding: 5px 20px;
  border-radius:5px;

}

.maldives_rooms_listing_middle_section_buttons option{
  background-color: #fff;

}
.font-12{
  font-size: 13px !important;
}
.font-14{
  font-size: 14px !important;
}

#maldive_room_date_selector {
  width: 100%;
  padding:5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

@media screen and (min-width: 1381px) and (max-width: 1440px){
  .maldives_rooms_listing_middle_section_buttons select{
    font-size: 14px !important;
    padding: 5px 8px !important;
  }
  .maldives_rooms_listing_middle_section_buttons label{
    font-size: 14px !important;
  }
  .maldives_room_check_availability_box h6{
    margin: 15px 0px 0px 0px;
  }
}

@media screen and (min-width: 1201) and (max-width: 1380px){
  .maldives_rooms_listing_middle_section_buttons select{
    font-size: 14px !important;
    padding: 5px 8px !important;
  }
  .maldives_rooms_listing_middle_section_buttons label{
    font-size: 14px !important;
  }
  .maldives_room_check_availability_box h6{
    margin: 15px 0px 0px 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px){
  .maldives_rooms_listing_middle_section_buttons select{
    font-size: 14px !important;
    padding: 5px 8px !important;
  }
  .maldives_rooms_listing_middle_section_buttons label{
    font-size: 14px !important;
  }
  .maldives_room_check_availability_box h6{
    margin: 15px 0px 0px 0px;
  }

}

@media screen and (min-width: 968px) and (max-width: 1024px){
  .maldives_rooms_listing_middle_section_buttons select{
    font-size: 14px !important;
    padding: 5px 8px !important;
  }
  .maldives_rooms_listing_middle_section_buttons label{
    font-size: 14px !important;
  }
  .maldives_room_check_availability_box h6{
    margin: 15px 0px 0px 0px;
  }
}