
.dashboard_main-upper_wrapper {
    background-color: #fff;
    box-shadow: 0px 0px 0px 1px #fff;
    border-radius: 12px;
    padding: 12px 25px;
    margin-top: 25px;
  }
  
  .dashboard_main-upper_wrapper h1 {
    font-family: "Inter", sans-serif;
    color: #1e1e1e;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0;
  }
  
  .dashboard_profile_img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: #f2f2f2;
  }
  
  .dashboard_profile_img img {
    height: 100%;
    width: 100%;
  }
  
  .dashboard_content {
    background-color: #fff;
    box-shadow: 0px 0px 0px 1px #fff;
    border-radius: 12px;
    padding: 50px;
    height: 84vh;
    margin-top: 20px;
  }
  
  /* /////////// */
  
  .edit_profile_form {
    width: 80%;
  }
  
  .edit_profile_form form label {
    font-size: 15px;
    margin-bottom: 3px;
    font-weight: 500;
    color: rgba(30, 30, 30, 0.5);
    font-family: "Inter", sans-serif;
  }
  
  .edit_profile_form form input {
    background: #f5f5f5;
    border-color: rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 8px;
    width: 80%;
  }
  
  .form-control:focus {
    border-color: rgba(0, 0, 0, 0.2);
    background: #f5f5f5;
    box-shadow: none;
  }
  

  
  .dashboard_profile_name {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    margin-left: 8px;
    font-weight: 500;
    color: rgba(30, 30, 30, 0.5);
  }
  
  .dashboard_screen_hero_banner {
    background-image: url(/public/img/agent-dashboard.png);
    background-position: center;
    background-size: cover;
    height: 350px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .dashboard_hero_content {
    width: 80%;
  }
  
  .dashboard_hero_content h1 {
    color: #fff;
    margin-bottom: 10px;
    text-align: left;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  
  .dashboard_hero_content p {
    font-size: 17px;
    color: #fff;
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }
  
  .dashboard_welcome_agents_cards {
    margin-top: 12px;
  }
  
  .welcome_upper_cards_wrapper {
    margin-top: 12px;
  }
  
  .welcome_upper_cards {
    height: 215px;
    width: 92%;
    border-radius: 12px;
    /* justify-content: center; */
    background-color: red;
    padding: 25px;
  }
  
  .welcome_lower_cards1 {
    margin-top: 25px;
    height: 145px;
    width: 98%;
    border-radius: 12px;
    background-color: red;
  }
  
  .welcome_lower_cards2 {
    margin-top: 25px;
    height: 145px;
    width: 92%;
    border-radius: 12px;
    background-color: red;
  }
  
  .welcome_upper_img {
    display: flex;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background: #ffffff;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .welcome_upper_img img {
    height: 45px;
    width: 45px;
    object-fit: contain;
  }
  
  .welcome_upper_content span {
    display: block;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    font-family: "Inter", sans-serif;
  }
  
  .welcome_upper_content h2 {
    font-size: 77px;
    font-weight: 700;
    margin: 4px 0;
    color: #fff;
    font-family: "Inter", sans-serif;
  }
  
  .welcome_upper_content p {
    font-size: 20px;
    color: #fff;
    font-family: "Inter", sans-serif;
  }
  
  .dashboard_heading h2 {
    font-size: 34px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  
  .lower_card_inner {
    border-left: 1px solid #fbbb0b;
    width: 50%;
  }
  
  .lower_card2_inner {
    border-left: 1px solid #ffffff;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .welcome_lower_heading h4 {
    font-size: 30px;
    font-weight: 700;
    color: #11abe7;
    font-family: "Inter", sans-serif;
  }
  
  .welcome_lower_content p {
    font-size: 32px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 0;
    font-family: "Inter", sans-serif;
  }
  
  .lower_card2_img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lower_card2_img img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
  
  .welcome_lower_cards2 h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 3px 0;
    color: #ffffff;
    font-family: "Inter", sans-serif;
  }
  
  .welcome_lower_cards2 p {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    color: #ffffff;
    font-family: "Inter", sans-serif;
  }
  