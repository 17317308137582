.blogs_wrapper{
    padding:50px 100px;
 }

 .blogs_wrapper .card {
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 0;
 }

 .blogs_wrapper .card .card-date span svg{
     color: #11ABE7;
     font-size: 146x;

 }

 .blogs_wrapper .card .card-date span{
  color: #11ABE7;
  font-size: 16px;
  color: #767676;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
 }

 .blogs_wrapper .card .card-text{
   color: #767676;
   font-size: 13px;
   font-family: "Inter", sans-serif;
 }

 .blogs_wrapper .card button{
  border: none;
 }

 .blogs_wrapper .card .btn {
  color: #11ABE7;
  font-size: 15px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  padding: 0;
 }

 .blogs_wrapper .card .card-title{
   font-size: 24px;
   font-weight: 600;
   color: #000;
   margin: 14px 0;
   font-family: "Inter", sans-serif;
 }

 .card-date{
  width: 77%;
 }

 
 @media screen and (min-width: 1380px) and (max-width: 1440px){
  .blogs_wrapper .card .card-title{
    font-size: 18px;
  }
 }

@media screen and (min-width: 1201) and (max-width: 1380px){
  .blogs_wrapper .card .card-title{
    font-size: 18px;
  }
 }

@media screen and (min-width: 1025px) and (max-width: 1200px){
  .blogs_wrapper .card .card-title{
    font-size: 18px;
  }
 }

@media screen and (min-width: 968px) and (max-width: 1024px){
  .blogs_wrapper .card .card-title{
    font-size: 18px;
  }
 }

@media screen and (min-width: 526px) and (max-width: 967px){
  .blogs_wrapper .card .card-title{
    font-size: 18px;
  }
 }

@media screen and (min-width: 425px) and (max-width: 525px){

 }
@media screen and (min-width: 381px) and (max-width: 424px){

 }
@media screen and (min-width: 220px) and (max-width: 380px){

 }