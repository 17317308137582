.offers_wrappers{
    padding: 5% 5%;
    /* background-color: #f2f2f2; */
}

.allOffers_navtabs {
    display: flex;
    justify-content: center;
    /* margin-top: 20px; */
    background: white;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
    border-radius: 8px;
    /* padding: 10px 20px; */
    position: relative;
    font-family: "Inter", sans-serif;
  }

  .sec_heading h2{
    font-weight: 600;
  }
  .allOffers_navtab {
    margin: 0 10px;
    padding: 8px 20px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
    font-family: "Inter", sans-serif;
  }

  .allOffers_navtab:hover {
    color: #007BFF;
  }

  .allOffers_navtab.allOffers_active {
    color: #ffffff;
    font-weight: 600;
    background-color: #007BFF;
    border-radius: 10px;
  }

  .underline {
    position: absolute;
    bottom: 0;
    height: 2px;
    background: #007BFF;
    transition: left 0.3s ease, width 0.3s ease;
  }

  .content {
    display: none;
    /* padding: 100px 20px 20px; */
    /* max-width: 800px; */
    /* height: 300px; */
  }

  .content.active {
    display: block;
  }

  .offer_tabs_cards{
     border: 1px solid #ddd;
     padding: 4px 12px;
     margin-top: 12px;
     border-radius: 7px;
  }

  .offer_card_heading span{
      display: block;
      font-size: 12px;
      color: #999999;
      font-family: "Inter", sans-serif;
  }

  .offer_card_content{
    padding: 0;
  }

  .offer_card_heading h4 {
    font-size: 18px;
    text-align: left;
    line-height: 23px;
    font-weight: 700;
    margin-top: 7px;
    font-family: "Inter", sans-serif;
}

.offer_card_content p{
  font-size: 14px;
  color: #615d5d;
  font-family: "Inter", sans-serif;
  margin: 0px;
}

.offer_card_content_discount p{
  font-size: 14px;
  color: #333;
  font-family: "Inter", sans-serif;
  margin: 0px;
  font-weight: 600;
}

.offer_card_btn{
  text-align: end;
}

.offer_card_btn button{
  border: none;
 }

.offer_card_btn .btn {
  color: #11ABE7;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  padding: 0;
  text-align: end;
 }

.offers_tabs_card_img{
  display: flex;
  align-items: center;
  height: 100%;
}
.offers_tabs_card_img img{
  width: 100%;
  border-radius: 8px;
}

.view_all_offers{
  cursor: pointer;
}
.view_all_offers:hover{
  color: blue;
  text-decoration: underline;
}
.offer_card_hotel_type{
  color:rgb(146 182 88) !important;
  font-weight: 600;
  letter-spacing: 1.1px;
  font-size: 14px !important;
}