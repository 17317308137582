.similar_property_wrapper{
    padding: 0px 100px;
  }
  
  .similar_property_wrapper .similar_property{
    width: 100%;
    padding: 25px 0px;
    background-color: #fff;
  }
  
  
  .similar_property_wrapper .similar_property .card{
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 8px;
  }
  
  .similar_property_cards .card-body{
    padding: 16px 0;
  }
  
  .similar_property_cards .card-body .card-title{
     font-size: 20px;
     font-weight: 700;
     color: #1E1E1E;
     font-family: "Inter", sans-serif;
  }
  
  .card-head ul{
    margin-top: 4px;
  }
  
  .card-head ul li{
    font-size: 15px;
    color: #8D8D8D;
    font-family: "Inter", sans-serif;
    list-style: none;
  }
  
  .card-head ul li img{
    height: 16px;
    width: 18px;
  }
  
  .similar_property_cards .card-body .sub-title{   
    font-size: 16px;
    font-weight: 500;
    color: rgba(30, 30, 30, 0.5);
    font-family: "Inter", sans-serif;
  }
  
  .similar_property_cards .card-body .card-text{
    font-size: 13px;
    font-weight: 500;
    color: rgba(30, 30, 30, 0.5);
    font-family: "Inter", sans-serif;
  }
  
  .similar_property_cards .card-body a{
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    width: 165px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }