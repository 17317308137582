.pakcgae_details_main {
    padding: 1% 6%;
}

.pakcgae_details_main strike{
    font-size: 16px;
   color: grey;
}

.pakcgae_details_main h2{
    font-weight: 600;
}

.pakcgae_details_main h6{
    color: #ff6600;
}

.package_main_price{
    font-weight: 700;
    margin-left: 10px;
}

.package_book_now_button{
    border: none;
    border-radius: 10px;
    color: #fff;
    padding: 8px 20px;
    background-color: #11abe7;
}
.package_details_main_add_ons p{
    color: #ff6600;
    cursor: pointer;   
}
.package_details_apply_coupon_button{
    border: none;
    border-radius: 10px;
    color: #fff;
    padding: 8px 20px;
    background-color: #11abe7;   
}

.package_details_main_price h5{
    color: #626161;
    font-weight: 500;
    font-size: 18px;
    text-decoration: line-through;
 
}

.package_details_main_price h4{
    color: #333;
    font-weight: 700;
    margin-right: 5px;

}

.package_details_main_coupons_and_addons p{
    cursor: pointer;
}

.package_details_main_coupons_and_addons p:hover{
    color: #11abe7;
    text-decoration: underline;
}