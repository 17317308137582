.amenities_wrapper{
    padding: 0px 100px;
   }
  
   .details_tabs_content_wrapper{
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
   }
  
   .details_tabs_content_wrapper ul{
    margin: 0px;
    padding: 0px;
    margin-right: 30px;
   }
  
   .details_tabs_content_wrapper ul li{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-top: 10px;
   }
  
   .details_tabs_content_wrapper ul li p{
    font-size: 14px;
    color: #1E1E1E;
    font-weight: 500;
    margin: 2px 0;
    margin-left: 5px;
    font-family: "Inter", sans-serif;
   }