.top_destinations_wrapper{
    padding: 2% 5% ;
    background-color: #F2F2F2;
 }

 .top_destinations_wrapper .container-fluid{
    padding: 0;
 }

 .top_destinations_wrapper .sec_heading{
    padding-left: 100px;
 }

 .top_destinations_wrapper .slick-prev, .slick-next {
   /* display: none !important; */
 }

 .top_destinations_wrapper .card-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
 }

 .top_destinations_wrapper .card-text{
  color: #767676;
  font-size: 13px;
  font-family: "Inter", sans-serif;
 }

 .top_destinations_wrapper h5{
  font-size: 17px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #000;
}

 .top_destinations_wrapper h6{
    margin-top: 2px !important;
    color: #767676;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 7px;
    font-family: "Inter", sans-serif;
  }

  .destination_offer{
    background: #72CF02;
    border-radius: 40px;
    padding: 4px 10px;
  }

  .destination_offer span{
     font-size: 13px;
     font-weight: 500;
     font-family: "Inter", sans-serif;
  }

  .top_destinations_wrapper button button {
    border: none;
  }

  .top_destinations_wrapper .card{
    border-radius: 12px;
    padding: 8px;
  }

 
  .top_destinations_wrapper .card button {
    color: #11ABE7;
    font-size: 15px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    padding: 0;
    background-color: #fff;
    outline: none;
    border: none;
   }

  .destination_card_footer{
    display: flex;
    justify-content: space-between;
  }

  .destination_card_footer .rating{
    font-size: 14px;
    font-family: "Inter", sans-serif;
    display: flex;
    align-items: center;
  }

  .destination_card_footer .rating svg{
    color: #72CF02;
    font-size: 16px;
  }