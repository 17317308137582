.resort_overview_main{
    padding: 0px 100px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
   }
  
   .resort_details_overview_content_wrapper{
    background-color: #fff;
    padding: 6px 0px;
    border-radius: 8px;
   }
  
   .detail_tabs_heading,  .resort_details_overview_content{
     width: 100%;
   }
  
  
  
   .detail_tabs_heading h2{
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
  
   }
  
  
   .resort_details_overview_content p{
     color: #000000;
     font-size: 16px;
     font-family: "Inter", sans-serif;
     text-align: justify;
   }