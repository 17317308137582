.travels_wrapper{
    padding: 50px 100px;
    background-color: #F2F2F2;
   }

   .travels_inner_wrapper {
    background: url(../../../../public/img/travel-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 35px 40px;
   }

   .travel_heading h2{
    font-size: 32px;
    margin-bottom: 12px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
   }

   .travel_heading span{
    display: block;
    margin: 0;
    font-size: 17px;
    font-weight: 500;

    font-family: "Inter", sans-serif;
   }

   .travel-list{
    margin-top: 25px;
   }

   .travel-list ul li{
     font-size: 16px;
     margin: 15px 0;
     display: inline-block;
     font-weight: 600;
     font-family: "Inter", sans-serif;
   }

   .travel-list .offer_card_btn{
    text-align: end;
  }

  .travel_btn {
    margin-top: 17px;
  }
  
  .travel_btn button{
    border-radius: 12px;
    background: #006056;
   }

  .travel_btn button {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    padding: 10px 20px;
    outline: none;
    border: none;
   }
